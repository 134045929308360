import { Dialog } from "vant";
const downloadQueue = new Map();
export default {
  bind(el, binding) {
    el.addEventListener("click", async () => {
      const url = binding.value.url;
      const fileName = binding.value.fileName || "download";

      // 如果这个URL已经在下载队列中，则不执行新的下载
      if (downloadQueue.has(url)) {
        console.log("Download already in progress for:", url);
        await Dialog.alert({
          message: "正在下载中！"
        });
        return;
      }
      await Dialog.confirm({
        title: "提示",
        message: "是否下载《2024北京自然城市古树观赏指南》？"
      });

      // 将URL添加到下载队列
      downloadQueue.set(url, true);

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error)
        .finally(() => {
          // 下载完成后从队列中移除
          downloadQueue.delete(url);
        });
    });
  },

  // 在指令解绑时清理事件监听器
  unbind(el) {
    el.removeEventListener("click", el.__downloadHandler);
  }
};
